function masonryGalerias() {
    var count_images = $(".JSlightGallery img").length;
    var count = 0;
    $(".JSlightGallery img").on("load", function () {
        if (++count == count_images) {

            console.log("ejecutando masonry....");

            //Ajuste visualización masonry para galerias
            $('.pageCont > .container > .left > .galeriasPage .JSlightGallery').masonry({
                itemSelector: '.item',
                columnWidth: '.item',
            });

            //Ajuste visualización masonry para galeriass
            $('.v2-content.v2-deportes-page .v2-cat-multimedia .JSlightGallery').masonry({
                itemSelector: 'a',
                columnWidth: 'a',
            });

            //Ajuste visualización masonry para fotos-cancha
            $('.v2-content.v2-deportes-page .v2-cat-cancha .JSlightGallery').masonry({
                itemSelector: 'a',
                columnWidth: 'a',
            });
        }
    });
}

$(document).ready(function(){
 
/* ================================================= DOCUMENT READY ================================================= */
    //Ajuste imagenes galerías
    masonryGalerias();
    //Checkeo URL
    console.log('URL: '+mainUrl);
    
    //Excepción para hash en url
    checkDisabledExceptions.push('#video','#v2-dep-quienes','#v2-carrusel-socios');
    
    //Evento galerías pagina siguiente/anterior
    $(document).on("onNextPageChange.lg", function(event){
        var contenedor = ".pageCont > .container > .left .paginator .items .next";
        if($(contenedor).length > 0){
            window.location.href = $(contenedor).attr("href"); 
        }
    });
    $(document).on("onPrevPageChange.lg", function(event){
        var contenedor = ".pageCont > .container > .left .paginator .items .prev";
        if($(contenedor).length > 0){
            window.location.href = $(contenedor).attr("href");
        }
    });
    
    //Bloqueo de click para hash de video y carga de popup
    $(document).on("click", "a[href*=#video]", function(e){
        e.preventDefault();
        var source = $(this).attr("href").split("#video=");
        videoLaunch(source[1], true, '', true);
    });
    
    //Carga de video segun hash
    if(window.location.hash.indexOf('#video=') >= 0){
        var hash = window.location.hash.substring(1);
        var source = new Array();
            source = hash.split('video=');
        videoLaunch(source[1], true, '', true);
    }
    
    //Validación formulario socios
    $('.v2-socios .form-login').validateForm({
        noValidate: null,
        hasConfirm: false,
        customValidate: ['validarRut', '.form-control-rut', 'Por favor ingrese un RUT válido <i>(Sin puntos y con guión)</i>'],
        resetSubmit: true,
    });
    
    $('.v2-socios .form-crear').validateForm({
        noValidate: null,
        hasConfirm: false,
        customValidate: ['validarRut', '.form-control-rut', 'Por favor ingrese un RUT válido <i>(Sin puntos y con guión)</i>'],
        resetSubmit: true,
    });
    
    $('.v2-socios .form-cambiar').validateForm({
        noValidate: null,
        hasConfirm: false,
        customValidate: ['validarRut', '.form-control-rut', 'Por favor ingrese un RUT válido <i>(Sin puntos y con guión)</i>'],
        resetSubmit: true,
    });
    
    //Validación formulario deportes
    //$('.v2-deportes-page .v2-cat-calendario .v2-inscripcion').validateForm({
    //    noValidate: null,
    //    hasConfirm: false,
    //    customValidate: ['validarRut', '.form-control-rut', 'Por favor ingrese un RUT válido <i>(Sin puntos y con guión)</i>'],
    //    resetSubmit: true,
    //});
    
    //Ver campeones deportes
    var v2deportesCampeones = 0;
    $(document).on("click", ".v2-deportes-page .v2-cat-historia .v2-campeones a", function(){
        var altura = $(".v2-deportes-page .v2-cat-historia .v2-campeones span").css("height");
 
        if(v2deportesCampeones==0){
            $(".v2-deportes-page .v2-cat-historia .v2-campeones .texto").css("max-height",altura);
            $(this).html("Ocultar Todo");
            v2deportesCampeones = 1;
        }
        else{
            $(".v2-deportes-page .v2-cat-historia .v2-campeones .texto").css("max-height","378px");
            $(this).html("Ver Todo");
            v2deportesCampeones = 0;
        }
 
    });
        
        //click iconos socios page
    $(".v2-content.v2-socios-page .v2-bloque-3 .item").click(function(){
        if(isMobile){
            autoScroll('.v2-content.v2-socios-page .v2-bloque-4',true,170);
        }
        
        $(".v2-content.v2-socios-page .v2-bloque-3 .item").removeClass('active');
        $(this).addClass('active');
        
        var numero = $(this).index();
        
        $(".v2-content.v2-socios-page .v2-bloque-4 .left").removeClass('active');
        $(".v2-content.v2-socios-page .v2-bloque-4 .left:nth-child("+numero+")").addClass('active');
    });
    
    //click para abrir buscador
    $(".menu > .container > span .buscar2 .glyphicon").click(function(){
        if($(this).parent().find("input").css("display")=="none"){
            $(this).parent().find("input").css("display","block");
        }
        else{
            $(this).parent().find("input").css("display","none");
        }
    });
    
    //Enter para buscador
    $(".menu > .container > span .buscar2").find("input").keyup(function(e){
        if (e.which == 13) {
            Buscar($(this).parent());
        }
    });
    
    //Click abrir formulario socios
    $(".v2-socios-formulario-launch").click(function(){
        if($(".v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario").hasClass("hidden")){
            $(".v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario").removeClass("hidden");
        }
        else{
            $(".v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario").addClass("hidden");
        }
    });
    
    //Validación formulario socios admision
    $('.v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario form').validateForm({
        noValidate: null,
        hasConfirm: true,
        customValidate: null,
        resetSubmit: true,
        ajax: {
            url:  $('.v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario form').attr("action"),
            data: $('.v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario form').serialize(),
            dataType: "json",
            type: $('.v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario form').attr("method"),
            beforeSend: function(){
                var submit = $('.v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario form').find("button[type=submit]");
                if(submit.length > 0){
                    submit.button("loading");
                }
            },
            complete: function(e){
                var submit = $('.v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario form').find("button[type=submit]");
                if(submit.length > 0){
                    submit.button("reset");
                }
            },
            error: function(e){
                console.log(e);
                alert(e.responseText);
                var submit = $('.v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario form').find("button[type=submit]");
                if(submit.length > 0){
                    submit.button("reset");
                }
            },
            success: function (res) {
                var alert_success = $('.v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario form').find("div.alert.alert-success");
                var alert_danger = $('.v2-content.v2-socios-page .v2-bloque-4.formulario .v2-formulario form').find("div.alert.alert-danger");
                if(res.success){
                    alert_success.fadeIn();
                    alert_danger.hide();
                }else{
                    alert_success.hide();
                    alert_danger.fadeIn();
                }
            }
        }
 
    });
    
/* ================================================= DOCUMENT READY ================================================= */
 
});
 
//Form validate custom
function validarRut(campo)
{   
    var tmp     = campo.split('-');
    var digv    = tmp[1]; 
    var rut     = tmp[0];
    
    if (!/^[0-9]+-[0-9kK]{1}$/.test(campo)){
        return false;
    }
    if(digv == 'K'){
        digv = 'k';
    }
    return validarRutDv(rut)==digv?true:false;
}
function validarRutDv(digito)
{
    var M=0;
    var S=1;
    for(;digito;digito=Math.floor(digito/10)){
        S=(S+digito%10*(9-M++%6))%11;
    }
    return S?S-1:'k';
}
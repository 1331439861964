$(document).on("responsiveCode", function(event, bodyWidth, bodyHeight, bodyOrientation, bodyScreen){
    
/* ================================================= RESPONSIVE CODE ================================================= */
    
    //Checkeo dimensiones body
    $("body").attr("window-size",bodyWidth+"x"+bodyHeight);
    
	//Ajuste imagenes galerías
    masonryGalerias();
    
    //Sidebar Scroll
    if(!isHome && $('.pageCont > .container > .left.withSidebar').length > 0)
    {
        function sidebarScroll(position)
        {
            var headerAltura = $('body > .header').outerHeight() +
                               $('body > .menu').outerHeight() +
                               $('body > .pageBanner').outerHeight() +
                               $('body > .breaking').outerHeight() +
                               $('body > .pageTitle').outerHeight();
 
            var sidebarAlto = $('.pageCont > .container > .right > .title').outerHeight() +
                              $('.pageCont > .container > .right > .items').outerHeight();
 
            var sidebarAncho = $('.pageCont > .container > .right').width();
 
            var sidebarAltoMain = $('.pageCont > .container > .right').height();
 
            if(position > headerAltura)
            {
                if(position < (headerAltura+sidebarAltoMain-sidebarAlto)) 
                { 
                    $('.pageCont > .container > .right > .title').css({'width':sidebarAncho,
                                                                       'position':'fixed',
                                                                       'top':'20px',
                                                                       'bottom':'inherit'});
                    $('.pageCont > .container > .right > .items').css({'width':sidebarAncho,
                                                                       'position':'fixed',
                                                                       'top':'55px',
                                                                       'bottom':'inherit'});
                }
                else 
                {
                    $('.pageCont > .container > .right > .title').css({'width':sidebarAncho,
                                                                       'position':'absolute',
                                                                       'top':'inherit',
                                                                       'bottom':sidebarAlto});
                    $('.pageCont > .container > .right > .items').css({'width':sidebarAncho,
                                                                       'position':'absolute',
                                                                       'top':'inherit',
                                                                       'bottom':'27px'});
                }
            }
            else
            { 
                $('.pageCont > .container > .right > .title').removeAttr('style');
                $('.pageCont > .container > .right > .items').removeAttr('style');
            }
        }
 
        if(bodyWidth > bodyScreen.desktop)
        {
            sidebarScroll($(window).scrollTop());   
 
            $(window).scroll(function() {
 
                sidebarScroll($(this).scrollTop()); 
 
            });
        }
        else
        {
            $(window).unbind('scroll');
            
            $('.pageCont > .container > .right > .title').removeAttr('style');
            $('.pageCont > .container > .right > .items').removeAttr('style');
        }
    }
    
    //Ajuste para video en socios page
    $(".v2-content.v2-socios-page .v2-bloque-1").each(function(){
        var altoGet = $(this).find(".pageBanner .carousel").height();  
       
        if(bodyWidth > bodyScreen.tablet){
			
			onElementHeightChange(".v2-content.v2-socios-page .v2-bloque-1 .pageBanner .carousel", function(){
				responsiveCode();
			});
			
            $(this).find(".v2-main-video").css("height", altoGet);
        }
        else{
            $(this).find(".v2-main-video").css("height","auto");
        }
    });
 
 
 
/* ================================================= RESPONSIVE CODE ================================================= */
 
});
function filterBySocio(mainClass, nextClass, lastClass)
{
    $(document).on('click', `div.${mainClass}`, function(event) {

        var vm = $(this)
        var id = vm.find(`.${mainClass}`).attr('name').slice(-1)
        var firstSocio = vm.find(`.${mainClass}:checked`).val();
        var nextSocio = vm.parents('.main_div').find(`input.${nextClass}:checked`).val()
        var lastSocio = vm.parents('.main_div').find(`input.${lastClass}:checked`).val()

        if(firstSocio == 'No' && nextSocio == 'No' && lastSocio == 'No')
            vm.parents('.main_div').find(`.campos_titular${id}`).show().html(`
                <div class="campo izq">
                    <label for="rut_socio_titular${id}"><img src="https://${window.location.hostname + '/assets/img/campo_icon.png'}"> Rut socio titular <sup>${id}</sup></label>
                    <input type="text" class="not-validate" name="rut_socio_titular${id}" id="rut_socio_titular${id}"/>
                </div>
                <div class="campo der">
                    <label for="nombre_socio_titular${id}"><img src="https://${window.location.hostname + '/assets/img/campo_icon.png'}"> Nombre Completo socio titular <sup>${id}</sup></label>
                    <input type="text" class="not-validate" name="nombre_socio_titular${id}" id="nombre_socio_titular${id}"/>
                </div>
                <div class="campo izq">
                    <label for="telefono_socio_titular${id}"><img src="https://${window.location.hostname + '/assets/img/campo_icon.png'}"> Telefono socio titular <sup>${id}</sup></label>
                    <input type="text" class="not-validate" name="telefono_socio_titular${id}" id="telefono_socio_titular${id}"/>
                </div>
                <div class="campo der">
                    <label for="email_socio_titular${id}"><img src="https://${window.location.hostname + '/assets/img/campo_icon.png'}"> E-Mail socio titular <sup>${id}</sup></label>
                    <input type="text" class="not-validate" name="email_socio_titular${id}" id="email_socio_titular${id}"/>
                </div>
            `)
        else
            vm.parents('.main_div').find(`.campos_titular${id}`).hide().html('')
    });
}

filterBySocio('radioSocio', 'radioSocioMadre', 'radioSocioPadre')
filterBySocio('radioSocioMadre', 'radioSocioPadre', 'radioSocio')
filterBySocio('radioSocioPadre', 'radioSocio', 'radioSocioMadre')

function Buscar(Contenedor){

    var Enlace = $(Contenedor).attr("url");
    var Boton = $(Contenedor).find("input");
    var Texto = $(Contenedor).find("input").val().replace(/(<([^>]+)>)/ig,"");


    if ( Boton.val() == "" ||
         Boton.val() == null ||
         Boton.val() == "undefinied" )
    {
        showAlert("Busqueda","Por favor ingrese su busqueda");
    }
    else
    {
        window.location='/articles?search='+Texto;
    }
}

function pageContResize(){
    $(".pageCont").each(function(){
        var altoTexto1 = $(".pageCont > .container").css("min-height");
        var altoTexto2 = $(".pageCont > .container > .left").height();

        if(!(altoTexto2 < altoTexto1))
        {
            //console.log("es MAYOR al contenedor");
            $(".pageCont > .container > .right").css("min-height",altoTexto2);
        }
    });
}

function breakingInfo(ID){

    var contenido = $(".breaking > .container > span > .center .breakingInfoText.breakingItem_"+ID);
    var titulo = $(".breaking > .container > span > .center .breakingInfoText.breakingItem_"+ID).find(".gaSend").html();

    showContent("Breaking News", contenido);

    //alert(titulo);
    ga('send', 'event', 'Breaking News', 'Click', titulo); //Google Analytics
}
function terminosInfo(){

    var contenido = $(".camposGeneral .campoFinal .checkBoxGroup .terminosInfoText");

    showContent("Términos y condiciones", contenido);
}
function reglamentosInfo(){

    var contenido = $(".pageCont > .container > .left > .restaurantsPage .contenidos .rightSide .reglamentosInfoText");

    showContent("Reglamentos de cortesía y etiqueta", contenido);
}
function reglamentosDepInfo(){

    var contenido = $(".pageCont > .container > .left > .deportesPage .reglamentosDepInfoText");

    showContent("Reglamento", contenido);
}
function comiteInfo(titulo){

    var contenido = $(".pageCont > .container > .left > .deportesPage .infoContactoDep .comiteInfoText");

    showContent("Comité de "+titulo, contenido);
}
function calendarioInfo(ID){

    var titulo = $(".calendarioInfoText.calendarioItem_"+ID).attr("titulo");
    var fecha = $(".calendarioInfoText.calendarioItem_"+ID).attr("fecha");
    var contenido = $(".calendarioInfoText.calendarioItem_"+ID);

    contenido.prepend("<p><b>"+fecha+"</b></p>");
    showContent(titulo, /*"<b>"+fecha+"</b>"+*/contenido);

    ga('send', 'event', 'Fecha Calendario', 'Click', titulo+' - '+fecha); //Google Analytics
}
function eventosSocios(ID,titulo){

    var contenido = $(".eventosSocios.eventosItem_"+ID);

    contenido.prepend("<p>Tienes las siguientes áreas disponibles para este servicio:</p>");
    showContent(titulo, contenido);
}
function clubInfo(titulo){

    var contenido = $(".pageCont > .container > .left > .clubPage .texto .botonInfoText");

    showContent(titulo, contenido);
}
function actividadInfo(ID,titulo){

    var contenido = $(".pageCont > .container > .left > .actividadesPage .servicios .items .actividadInfoText.actividadItem_"+ID);

    showContent(titulo, contenido);
}

function bannerPopup(ID,titulo){

    var contenido = $("#bannerPopup_"+ID);

    showContent(titulo, contenido);

    //alert(titulo);
    ga('send', 'event', 'Banner Popup', 'Click', titulo); //Google Analytics

}
function botonVisitas(titulo){

    var contenido = $(".botonVisitasCont");

    showContent(titulo, contenido);
}

function deportesSpecial(tipo, val1, val2){


    if(tipo=="tarjeta")
    {
        var titulo = "Tarjeta de Juego";
        var contenido = '<div class="deportesLaunchImage"><img src="'+val1+'"><a href="'+val2+'" class="btn btn-success" target="_blank">Descargar Imagen</a></div>';

        showAlert(titulo, contenido);
    }
    else if(tipo=="cancha")
    {
        var titulo = "Cancha de golf";
        var contenido = '<div class="deportesLaunchMap"><img src="'+val1+'"><a href="'+val2+'" class="btn btn-success" target="_blank">Ver en Google Maps</a></div>';

        showAlert(titulo, contenido, 'large');
    }

}

function mover(objetivo){
    autoScroll(objetivo,true,125);
}

function ValidaRutTxt (rutCompleto) {
    if (!/^[0-9]+-[0-9kK]{1}$/.test( rutCompleto.val() )){
        $(rutCompleto).css("color","#FF0000").css("border-color","#FF0000");
        return false;
    }
    else{
        $(rutCompleto).css("color","#333333").css("border-color","#AAAAAA");
    }
    var tmp     = rutCompleto.val().split('-');
    var digv    = tmp[1];
    var rut     = tmp[0];

    if ( digv == 'K' ) digv = 'k' ;
    //return ( dv(rut) == digv );

    var digitoValido = true;
    if(dv(rut) == digv){
        digitoValido = true;
        $(rutCompleto).css("color","#333333").css("border-color","#AAAAAA");
    }
    else{
        digitoValido = false;
        $(rutCompleto).css("color","#FF0000").css("border-color","#FF0000");
    }
    return digitoValido;

}
function dv (T){
    var M=0,S=1;
    for(;T;T=Math.floor(T/10))
        S=(S+T%10*(9-M++%6))%11;
    return S?S-1:'k';
}
function emailValido(email){

    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

    if (email == ''){
        return false;
    }

    if (emailReg.test(email)){
        return true;

    }else{
        return false;
    }
}
function checkEmpty(campo){
    if ( campo == "" ||
         campo == "--" ||
         campo == null ||
         campo == "undefinied" ){
             console.log('false');
             return false;
         }
         else if(/^\s*$/.test(campo)){
             console.log('false');
             return false;
         }
         else{
             console.log('true');
             return true;
         }
}

//Verificar apoderados vacíos
function checkEmptyParent(persona)
{
    var empty = true;

    var mainClass = $('.familia-activa')
    var rut = mainClass.find(`input[name*="rut_${persona}"]`).val();
    var nombre = mainClass.find(`input[name*="nombre_${persona}"]`).val();
    var telefono = mainClass.find(`input[name*="telefono_${persona}"]`).val();
    var email = mainClass.find(`input[name*="email_${persona}"]`).val();

    if(rut == '' && nombre == '' && telefono == '' && email == '')
    {
        empty = false;
    }

    if(rut != '' || nombre != '' || telefono != '' || email != '')
    {
        if (rut != '') {
            if(!ValidaRutTxt(mainClass.find(`input[name*="rut_${persona}"]`)))
            {
                mainClass.find(`input[name*="rut_${persona}"]`).css("color","#FF0000").css("border-color", "#FF0000");
                return empty = 'error';
            }
        }

        empty = true;
    }

    return empty;
}

//Setear inputs
function setInputIfEmpty()
{
    var mainClass = $('.familia-activa')

    var check = true

    if (!checkEmptyParent('madre') && !checkEmptyParent('padre'))
    {
        mainClass.find('.tipoMadre, .tipoPadre').css("color","#FF0000").css("border-color", "#FF0000");
        check = false;
    }
    else
    {
        if(checkEmptyParent('madre'))
        {
            mainClass.find('.tipoPadre').css("color","#333333").css("border-color","#AAAAAA");
            mainClass.find('.tipoMadre').each(function(index, el){
                if($(this).val() == '')
                {
                    $(this).css("color", "#FF0000").css("border-color", "#FF0000");
                    check = false;
                }
                else
                {
                    $(this).css("color","#333333").css("border-color", "#AAAAAA");
                }
            });
        }

        if(checkEmptyParent('padre'))
        {
            if(checkEmptyParent('madre'))
            {
                mainClass.find('.tipoMadre').each(function(index, el){
                    if($(this).val() == '')
                    {
                        $(this).css("color", "#FF0000").css("border-color", "#FF0000");
                    }
                    else
                    {
                        $(this).css("color","#333333").css("border-color", "#AAAAAA");
                    }
                });
            }
            else
            {
                mainClass.find('.tipoMadre').each(function(index, el){
                    $(this).css("color","#333333").css("border-color", "#AAAAAA");
                });
            }

            mainClass.find('.tipoPadre').each(function(index, el){
                if($(this).val() == '')
                {
                    $(this).css("color", "#FF0000").css("border-color", "#FF0000");
                    check = false;
                }
                else
                {
                    $(this).css("color","#333333").css("border-color", "#AAAAAA");
                }
            });
        }
    }

    console.log('El padre da ' + checkEmptyParent('padre') + ' y la madre da ' + checkEmptyParent('madre'));

    return check
}

/*CHECKEOS BASE REARMADOS*/
function checkEmptyGrupal(campoInput){

    var verdadero = true;

    $(campoInput).not('.not-validate').each(function(){

             if ($(this).parent().css('display') == 'none')
             {
                 //$(this).parent().attr("NOVISIBLE","NOVISIBLE");
                 console.log('true');
                 //verdadero = true;
             }
             else if ( $(this).val() == "" ||
                 $(this).val() == "--" ||
                 $(this).val() == null ||
                 $(this).val() == "undefinied" )
             {
                 console.log('false'); $(this).css("color","#FF0000").css("border-color","#FF0000");
                 verdadero = false;
             }
             else if(/^\s*$/.test( $(this).val() )){

                 console.log('false'); $(this).css("color","#FF0000").css("border-color","#FF0000");
                 verdadero = false;
             }
             else{
                 console.log('true');
                 $(this).css("color","#333333").css("border-color","#AAAAAA");
                 //verdadero = true;
             }
    });

    console.log("verdadero: "+verdadero);
    return verdadero;
}

function checkEmptyGrupalRut(campoInput){

    var verdadero = true;

    $(campoInput).not('.not-validate').each(function(){

         if ($(this).parent().css('display') == 'none')
         {
             //$(this).parent().attr("NOVISIBLE","NOVISIBLE");
             console.log('true');
             //verdadero = true;
         }
         else if ( !(ValidaRutTxt($(this))) )
         {
             console.log('false'); $(this).css("color","#FF0000").css("border-color","#FF0000");
             verdadero = false;
         }
         else{
             console.log('true');
             $(this).css("color","#333333").css("border-color","#AAAAAA");
             //verdadero = true;
         }
    });

    console.log("verdadero rut: "+verdadero);
    return verdadero;
}

function checkEmptyGrupalCheckbox(campoInput){

    var verdadero = true;


    $(campoInput).each(function(){

        $(this).attr("vacios","si");

        var item = $(this).find("input[type='checkbox']");

        for (var i = item.length -1; i >= 0; i--){

            if( item.eq(i).hasAttr("checked") || item.eq(i).attr("checked")=="checked" || item.eq(i).is(":checked") ){
                console.log("*** check "+item.eq(i).attr("name")+" true");
                $(this).attr("vacios","no");
            }

        }

        /*if( $(this).find("input[type='checkbox']").hasAttr("checked") ||  $(this).find("input[type='checkbox']").attr("checked")=="checked" ){
            //console.log("*** check "+item.eq(i).attr("name")+" true");
            console.log("*** check "+$(this).attr("name")+" true");
            $(this).attr("vacios","no");
        }*/

        if ($(this).css('display') == 'none'){
             console.log('true');
            $(this).attr("vacios","no");
        }
        if( $(this).attr("vacios") == "si" ){
            console.log('false');
            $(this).find("input[type='checkbox']").parent().css("color","#FF0000");
            verdadero = false;
        }else{
             console.log('true');
             $(this).find("input[type='checkbox']").parent().css("color","#333333");
             //verdadero = true;
        }


    });



    console.log("verdadero grupal: "+verdadero);
    return verdadero;
}

$(document).ready(function(){

/* ================================================= DOCUMENT READY ================================================= */

    /*$('*[fondo_pos]').each(function(){
        imageFill($(this), $(this).attr('fondo_pos'));
    });*/

    if(isNavIE6 || isNavIE7 || isNavIE8){
        $(".camposGeneral .campo select").css("padding-right","0px");
        $(".camposGeneral .campo select").css("padding-top","0px");
        $(".camposGeneral .campo textarea").attr("style","min-width: inherit !important; min-height: inherit !important");
    }

    //Sociales ocultos
    if(!($(".footer > .container > .right .social").length > 0))
    {
        $(".footer > .container > .right").addClass("sinSocial");
    }

    //Sidebar
    if($(".pageCont > .container > .left.withSidebar").length > 0)
    {
        onElementHeightChange(".pageCont > .container > .left.isDesktop", function(){
            //console.log('la altura ha cambiado');
            pageContResize();
            responsiveCode();
        });
    }

    //Hover Menu
    function colorMenu()
    {
        if (/.cl\x2Fpage\x2Fquienes-somos/.test(self.location.href) ||
            /.cl\x2Fpage\x2Fhistoria/.test(self.location.href) ||
            /.cl\x2Fpage\x2Fmision/.test(self.location.href) ||
            /.cl\x2Fpage\x2Fdirectorio/.test(self.location.href) ||
            /.cl\x2Fpage\x2Freglamento/.test(self.location.href) ||
            /.cl\x2Fpage\x2Fpoliticas/.test(self.location.href) ||
            /.cl\x2Fpage\x2Fcuotas-sociales/.test(self.location.href) ||
            /.cl\x2Fpage\x2Fex-presidentes/.test(self.location.href)   ) {
            $(".menu > .container > span > .button:nth-child(2)").addClass("menuOn");
            $(".menu > .container > span > .button.first img").attr("src",$(".menu > .container > span > .button.first img").attr("src").replace("2.png", "1.png"));
            $(".menuMovil > span > .button:nth-child(2)").addClass("menuOn");
        }
        if (/.cl\x2Fpage\x2Fadmision/.test(self.location.href) ||
            /.cl\x2Fpage\x2Fsocios-activos/.test(self.location.href)   ) {
            $(".menu > .container > span > .button:nth-child(3)").addClass("menuOn");
            $(".menu > .container > span > .button.first img").attr("src",$(".menu > .container > span > .button.first img").attr("src").replace("2.png", "1.png"));
            $(".menuMovil > span > .button:nth-child(3)").addClass("menuOn");
        }
        if (/.cl\x2Fsports/.test(self.location.href) ||
                 /.cl\x2Fsection\x2Fgolf/.test(self.location.href) ||
                 /.cl\x2Fsection\x2Frugby/.test(self.location.href) ||
                 /.cl\x2Fsection\x2Fhockey/.test(self.location.href) ||
                 /.cl\x2Fsection\x2Ffutbol/.test(self.location.href) ||
                 /.cl\x2Fsection\x2Ftenis/.test(self.location.href) ||
                 /.cl\x2Fsection\x2Fsquash/.test(self.location.href) ||
                 /.cl\x2Fsection\x2Fpadel/.test(self.location.href) ) {
            $(".menu > .container > span > .button:nth-child(4)").addClass("menuOn");
            $(".menu > .container > span > .button.first img").attr("src",$(".menu > .container > span > .button.first img").attr("src").replace("2.png", "1.png"));
            $(".menuMovil > span > .button:nth-child(4)").addClass("menuOn");
        }
        if (/.cl\x2Factivities/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Ffitness/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fclases/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fnatacion/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fescuelas/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fsummer-winter-activities/.test(self.location.href) ) {
            $(".menu > .container > span > .button:nth-child(5)").addClass("menuOn");
            $(".menu > .container > span > .button.first img").attr("src",$(".menu > .container > span > .button.first img").attr("src").replace("2.png", "1.png"));
            $(".menuMovil > span > .button:nth-child(5)").addClass("menuOn");
        }
        if (/.cl\x2Fpage\x2Frestaurants/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fcomedor/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fbar/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fterraza/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fpub/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fsports-cafe/.test(self.location.href) ) {
            $(".menu > .container > span > .button:nth-child(6)").addClass("menuOn");
            $(".menu > .container > span > .button.first img").attr("src",$(".menu > .container > span > .button.first img").attr("src").replace("2.png", "1.png"));
            $(".menuMovil > span > .button:nth-child(6)").addClass("menuOn");
        }
        if (/.cl\x2Fpage\x2Feventos/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Factividades-sociales/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Feventos-socios/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fnoches-tematicas/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fcharlas-culturales/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fdestacados/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Finscripcion/.test(self.location.href) ) {
            $(".menu > .container > span > .button:nth-child(7)").addClass("menuOn");
            $(".menu > .container > span > .button.first img").attr("src",$(".menu > .container > span > .button.first img").attr("src").replace("2.png", "1.png"));
            $(".menuMovil > span > .button:nth-child(7)").addClass("menuOn");
        }
        if (/.cl\x2Farticles/.test(self.location.href)) {
            $(".menu > .container > span > .button:nth-child(8)").addClass("menuOn");
            $(".menu > .container > span > .button.first img").attr("src",$(".menu > .container > span > .button.first img").attr("src").replace("2.png", "1.png"));
            $(".menuMovil > span > .button:nth-child(8)").addClass("menuOn");
        }
        if (/.cl\x2Fpage\x2Fclub-house/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fliving/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fbridge/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fbiblioteca/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fplayroom/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fcamarines/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fjardines/.test(self.location.href) ||
                 /.cl\x2Fpage\x2Fvisitas/.test(self.location.href) ) {
            $(".menu > .container > span > .button:nth-child(9)").addClass("menuOn");
            $(".menu > .container > span > .button.first img").attr("src",$(".menu > .container > span > .button.first img").attr("src").replace("2.png", "1.png"));
            $(".menuMovil > span > .button:nth-child(9)").addClass("menuOn");
        }
        if (/.cl\x2Fpage\x2Fsugerencias/.test(self.location.href)   ) {
            $(".menu > .container > span > .button:nth-child(10)").addClass("menuOn");
            $(".menu > .container > span > .button.first img").attr("src",$(".menu > .container > span > .button.first img").attr("src").replace("2.png", "1.png"));
            $(".menuMovil > span > .button:nth-child(10)").addClass("menuOn");
        }
        /*else {
            //$(".menu > .container > span").find(".button").eq(0).addClass("menuOn");
            $(".menuMovil > span").find(".button").eq(0).addClass("menuOn");
        }*/

    }

    $(".menu > .container > span, .menuMovil > span").find(".button").hover(function(){
        $(".menu > .container > span, .menuMovil > span").find(".button").removeClass("menuOn");
        $(this).addClass("menuOn");

        if($(this).index()==0){
            $(".menu > .container > span .button.first img").attr("src",$(".menu > .container > span .button.first img").attr("src").replace("1.png", "2.png"));
        }
        else{
            $(".menu > .container > span .button.first img").attr("src",$(".menu > .container > span .button.first img").attr("src").replace("2.png", "1.png"));
        }

    }, function() {
        $('.menu > .container > span, .menuMovil > span').on('mouseleave', function(){
            $(this).find(".button").removeClass("menuOn");
            $(".menu > .container > span .button.first img").attr("src",$(".menu > .container > span .button.first img").attr("src").replace("2.png", "1.png"));
            colorMenu();
        });
    });

    colorMenu();

    //Buscar
    $(".menu > .container > span .buscar").find("input").keyup(function(e){
        if (e.which == 13) {
            Buscar($(this).parent());
        }
    });
    $(".menu > .container > span .buscar").find("span").click(function(e){

        Buscar($(this).parent());

    });

    //Menu Movil
    var menuMovil = 0;
    $(".menu > .container > span a.movil").click(function(){
        var menuHeight = $(".menuMovil span").height();

        if(menuMovil==0)
        {
            //$(".menuMovil").animate({"height":menuHeight},500);
            $(".menuMovil").css({"height":menuHeight}); //CSS Animado
            menuMovil = 1;
        }
        else
        {
            //$(".menuMovil").animate({"height":"0px"},500);
            $(".menuMovil").css({"height":"0px"}); //CSS Animado
            menuMovil = 0;
        }

    });

    //Breaking News
    $(".breaking > .container > span > .center").each(function(){

        var breakingLimit = $(this).find("span").length;
        var breakingLink = $(this).find("span a.linkDestino").attr("href");

        $(".breaking").attr("limit", breakingLimit);
        $(".breaking").attr("currentLink",breakingLink);

        $(this).find("span").eq(0).animate({opacity: 1},500);

        $(".breaking > .container > span > .right").find("a").attr("href", breakingLink);

    });


    //Poner en grupos
    var breakingStart = 0;
    var breakingEnd = $(".breaking").attr("limit");
    var breakingVel = $(".breaking").attr("speed");

    $(".breaking").attr("current", breakingStart);

    setInterval(function(){


        //$(".breaking > .container > span > .center").find("span").removeClass("visible");
        //$(".breaking > .container > span > .center").find("span").eq(breakingStart).next("span").addClass("visible");
        $(".breaking > .container > span > .center").find("span").animate({ opacity: 0 }, 500, function() {
            $(this).removeClass("visible");
            $(".breaking > .container > span > .center").find("span").eq(breakingStart).addClass("visible");
        });

        $(".breaking > .container > span > .center").find("span").eq(breakingStart).next("span").animate({ opacity: 1 }, 500, function() {
            $(".breaking > .container > span > .right").find("a").attr("href", $(".breaking > .container > span > .center").find("span a.linkDestino").eq(breakingStart).attr("href"));
        });

        breakingStart++;

        if(breakingStart > (breakingEnd - 1)){
            breakingStart = 0;

            //$(".breaking > .container > span > .center").find("span").removeClass("visible");
            //$(".breaking > .container > span > .center").find("span").eq(breakingStart).addClass("visible");
            $(".breaking > .container > span > .center").find("span").animate({ opacity: 0 }, 500, function() {
                $(this).removeClass("visible");
                $(".breaking > .container > span > .center").find("span").eq(breakingStart).addClass("visible");
            });

            $(".breaking > .container > span > .center").find("span").eq(breakingStart).animate({ opacity: 1 }, 500, function() {
                $(".breaking > .container > span > .right").find("a").attr("href", $(".breaking > .container > span > .center").find("span a.linkDestino").eq(breakingStart).attr("href"));
            });

        }

        $(".breaking").attr("current", breakingStart);


    }, breakingVel);


    if(isHome){//****** IS HOME ******//

    //Click Noticias Movil
    var scrollCurrent = 0;
    var scrollAmount = 0;

    $(".homeNoticias > .container > .items .buttonRight").click(function(){

        if(scrollCurrent==0){
            scrollAmount = 100;
            scrollCurrent = 1;
            console.log("(RIGHT) amount:"+scrollAmount+",current:"+scrollCurrent);
        }
        else if(scrollCurrent==1){
            scrollAmount = 200;
            scrollCurrent = 2;
            console.log("(RIGHT) mount:"+scrollAmount+",current:"+scrollCurrent);
        }
        else if(scrollCurrent==2){
            scrollAmount = 300;
            scrollCurrent = 3;
            console.log("(RIGHT) amount:"+scrollAmount+",current:"+scrollCurrent);
        }
        else if(scrollCurrent==3){
            scrollAmount = 0;
            scrollCurrent = 0;
            console.log("(RIGHT) amount:"+scrollAmount+",current:"+scrollCurrent);
        }

        //$( ".homeNoticias > .container > .items span").animate({ "margin-left": "-"+scrollAmount+"%"  }, 500 );
        $( ".homeNoticias > .container > .items span").css({ "margin-left": "-"+scrollAmount+"%"  }); //CSS Animado
    });

    $(".homeNoticias > .container > .items .buttonLeft").click(function(){

        if(scrollCurrent==0){
            scrollAmount = 300;
            scrollCurrent = 3;
            console.log("(LEFT) amount:"+scrollAmount+",current:"+scrollCurrent);
        }
        else if(scrollCurrent==1){
            scrollAmount = 0;
            scrollCurrent = 0;
            console.log("(LEFT) amount:"+scrollAmount+",current:"+scrollCurrent);
        }
        else if(scrollCurrent==2){
            scrollAmount = 100;
            scrollCurrent = 1;
            console.log("(LEFT) amount:"+scrollAmount+",current:"+scrollCurrent);
        }
        else if(scrollCurrent==3){
            scrollAmount = 200;
            scrollCurrent = 2;
            console.log("(LEFT) amount:"+scrollAmount+",current:"+scrollCurrent);
        }

        //$( ".homeNoticias > .container > .items span").animate({ "margin-left": "-"+scrollAmount+"%"  }, 500 );
        $( ".homeNoticias > .container > .items span").css({ "margin-left": "-"+scrollAmount+"%"  }); //CSS Animado
    });

    }//****** IS HOME ******//


    if(!(isHome)){//****** NOT HOME ******//

        //Hover Noticias
        $(".pageCont > .container > .left > .noticiasHome > .items").find("a").hover(function(){
            $(this).find(".left").addClass("hover");
        }, function() {
            $(this).find(".left").removeClass("hover");
        });

        //Hover Iconos
        $(".pageCont > .container > .left > .deportesReservasHome .items a, .pageCont > .container > .left > .eventosHomeActividades .items a, .pageCont > .container > .left > .restaurantsReservasHome .items a").hover(function(){
            $(this).find("img").addClass("hover");
            $(this).find(".titulo").addClass("hover");
        }, function() {
            $(this).find("img").removeClass("hover");
            $(this).find(".titulo").removeClass("hover");
        });


        //Margin Noticias
        $(".pageCont > .container > .left > .noticiasHome > .items").each(function(){
            $(this).find("a:last").css("margin-bottom","0px");
            $(this).find("a:last").css("padding-bottom","0px");
            $(this).find("a:last").css("background","none");
        });

        //Margin Buscador
        $(".pageCont > .container > .left > .buscadorHome > .items").each(function(){
            $(this).find("a:last").css("margin-bottom","0px");
            $(this).find("a:last").css("padding-bottom","0px");
            $(this).find("a:last").css("background","none");
        });

        //Formulario orden
        $(".camposGeneral").each(function(){
            $(this).find(".campo:odd").addClass("der");
            $(this).find(".campo:even").addClass("izq");

        });

        //Formulario radio
        $(".camposGeneral .campo .radioGroup .radioFakeOn").click(function(){
            var target = $(this).attr("for");

            $(this).parent().find("input[type='radio']").removeAttr("checked");
            $(this).parent().find("input[type='radio']").prop('checked', false); //ARREGLO CHECK
            $(this).parent().find(".radioFakeOn, .radioFakeOff").removeClass("isChecked");
            $(this).parent().find("#"+target).attr('checked', 'checked');
            $(this).parent().find("#"+target).prop('checked', true); //ARREGLO CHECK
            $(this).addClass("isChecked");
        });

        $(".camposGeneral .campo .radioGroup .radioFakeOff").click(function(){
            var target = $(this).attr("for");

            $(this).parent().find("input[type='radio']").removeAttr("checked");
            $(this).parent().find("input[type='radio']").prop('checked', false); //ARREGLO CHECK
            $(this).parent().find(".radioFakeOn, .radioFakeOff").removeClass("isChecked");
            $(this).parent().find("#"+target).attr('checked', 'checked');
            $(this).parent().find("#"+target).prop('checked', true); //ARREGLO CHECK
            $(this).addClass("isChecked");
        });

        //Formulario Textarea size
        $('.camposGeneral .campo textarea').focusin(function(){
            $(this).addClass("isClicked");
        });
        $('.camposGeneral .campo textarea').focusout(function(){
            $(this).removeClass("isClicked");
        });

        //Formulario Fecha
        //$(".camposGeneral .campo #fecha").datepicker().attr("readonly","readonly");
        //Nota: Esta en cada pagina ya que son distintos requisitos

        //Formulario Checked
        var formCheckedOn = 0;
        $(".camposGeneral .campoFinal .checkBoxGroup .checkBoxFake").click(function(){
            var target = $(this).attr("for");

            if(formCheckedOn==0){
                $(this).parent().find("#"+target).attr('checked', 'checked');
                $(this).parent().find("#"+target).prop('checked', true); //ARREGLO CHECK
                $(this).find("img").attr("src",$(this).find("img").attr("src").replace("1.png", "2.png"));
                formCheckedOn=1;
            }
            else{
                $(this).parent().find("#"+target).removeAttr('checked');
                $(this).parent().find("#"+target).prop('checked', false); //ARREGLO CHECK
                $(this).find("img").attr("src",$(this).find("img").attr("src").replace("2.png", "1.png"));
                formCheckedOn=0;
            }

        });

        //Formulario Select Cancha
        $(".camposGeneral .campo select#cancha").change(function() {

            var itemSelect = ($(this).find("option:selected").attr("value") - 1);
            $(".pageCont > .container > .left > .deportesReservas form .block .leftSide .foto .item").removeClass("isVisible");
            $(".pageCont > .container > .left > .deportesReservas form .block .leftSide .foto .item").eq(itemSelect).addClass("isVisible");

        });

        //Formulario Select EventosActividades
        $(".camposGeneral .campo select#tipoactividad").change(function() {

            var itemSelect = $(this).find("option:selected").attr("foto");
            var itemSelectFecha = $(this).find("option:selected").attr("fecha");
            var itemSelectTitulo = $(this).find("option:selected").attr("titulo");
            var itemSelectTexto = $(this).find("option:selected").attr("texto");

            $(".pageCont > .container > .left > .eventosReservas form .block .leftSide .foto2 .item2").removeClass("isVisible");
            $(".pageCont > .container > .left > .eventosReservas form .block .leftSide .foto2 .item2.eventoActividad_"+itemSelect).addClass("isVisible");
            $(".pageCont > .container > .left > .eventosReservas .panel-title").html(itemSelectTitulo);
            $(".pageCont > .container > .left > .eventosReservas .panel-body").html(itemSelectTexto);
            $(".camposGeneral .campo #fecha").attr("value",itemSelectFecha);

        });

        //Formulario Nº de Mesas
        $(".restaurantsReservas .camposGeneral .campo select#numeropersonas").change(function() {

            var numeroPersonas1A = $(this).find("option:selected").attr("value");
            var numeroPersonas1B = null;
            /******/
            if(numeroPersonas1A == 1 || numeroPersonas1A == 2 || numeroPersonas1A == 3){
                numeroPersonas1B = 1;
            }
            else if(numeroPersonas1A == 4 || numeroPersonas1A == 5 || numeroPersonas1A == 6){
                numeroPersonas1B = 2;
            }
            else if(numeroPersonas1A == 7 || numeroPersonas1A == 8){
                numeroPersonas1B = 3;
            }
            else if(numeroPersonas1A == 9 || numeroPersonas1A == 10){
                numeroPersonas1B = 4;
            }
            else if(numeroPersonas1A == 11 || numeroPersonas1A == 12){
                numeroPersonas1B = 5;
            }
            else if(numeroPersonas1A == 13 || numeroPersonas1A == 14){
                numeroPersonas1B = 6;
            }
            else if(numeroPersonas1A == 15 || numeroPersonas1A == 16){
                numeroPersonas1B = 7;
            }
            else if(numeroPersonas1A == 17 || numeroPersonas1A == 18){
                numeroPersonas1B = 8;
            }
            else if(numeroPersonas1A == 19 || numeroPersonas1A == 20){
                numeroPersonas1B = 9;
            }
            /******/
            $(".restaurantsReservas .camposGeneral .campo input#numeromesas").attr("value",numeroPersonas1B);

        });
        $(".restaurantsReservas .camposGeneral .campo input#numeromesas").each(function() {

            var numeroPersonas2A = $(".restaurantsReservas .camposGeneral .campo select#numeropersonas").find("option:selected").attr("value");
            var numeroPersonas2B = null;
            /******/
            if(numeroPersonas2A == 1 || numeroPersonas2A == 2 || numeroPersonas2A == 3){
                numeroPersonas2B = 1;
            }
            else if(numeroPersonas2A == 4 || numeroPersonas2A == 5 || numeroPersonas2A == 6){
                numeroPersonas2B = 2;
            }
            else if(numeroPersonas2A == 7 || numeroPersonas2A == 8){
                numeroPersonas2B = 3;
            }
            else if(numeroPersonas2A == 9 || numeroPersonas2A == 10){
                numeroPersonas2B = 4;
            }
            else if(numeroPersonas2A == 11 || numeroPersonas2A == 12){
                numeroPersonas2B = 5;
            }
            else if(numeroPersonas2A == 13 || numeroPersonas2A == 14){
                numeroPersonas2B = 6;
            }
            else if(numeroPersonas2A == 15 || numeroPersonas2A == 16){
                numeroPersonas2B = 7;
            }
            else if(numeroPersonas2A == 17 || numeroPersonas2A == 18){
                numeroPersonas2B = 8;
            }
            else if(numeroPersonas2A == 19 || numeroPersonas2A == 20){
                numeroPersonas2B = 9;
            }
            /******/
            $(this).attr("value",numeroPersonas2B);

        });


        //Ex Presidentes
        var exPresidentesTipo = 0;
        $("#exPresidentesBtn").click(function(){
            var exPresidentesAltura = $(".pageCont > .container > .left > .quienesPageExpresidentes .texto span").css("height");

            if(exPresidentesTipo==0){
                $(".pageCont > .container > .left > .quienesPageExpresidentes .texto").css("max-height",exPresidentesAltura);
                $(this).html("Ocultar Todo");
                exPresidentesTipo = 1;
            }
            else{
                $(".pageCont > .container > .left > .quienesPageExpresidentes .texto").css("max-height","366px");
                $(this).html("Ver Todo");
                exPresidentesTipo = 0;
            }

        });


        //Hover Galerias
        $(".pageCont > .container > .left > .galeriasPage .fotos span").hover(function(){
            $(this).find(".descarga").addClass("hover");
        }, function() {
            $(this).find(".descarga").removeClass("hover");
        });


        //Page Tabla
        $(".pageTable").parent().find("table").each(function() {

            $(this).attr("width","0");
            $(this).attr("border","0");
            $(this).attr("cellpadding","0");
            $(this).attr("cellspacing","0");

            $(this).find("col, colgroup").remove();

            $(this).find("th, tr, td").removeAttr("width");
            $(this).find("th, tr, td").removeAttr("height");

            $(this).find("tr:first-child").addClass("tablaTitle");

            if ($(this).find("th").length > 0){
                $(this).find("tr").find("th").addClass("tablaDato");
                $(this).find("tr").find("th:first-child").addClass("tablaFirst");
                $(this).find("tr").find("th:last-child").addClass("tablaLast");
            }
            else{
                $(this).find("tr").find("td").addClass("tablaDato");
                $(this).find("tr").find("td:first-child").addClass("tablaFirst");
                $(this).find("tr").find("td:last-child").addClass("tablaLast");
            }

            $(this).find("tr").not(":first-child").addClass("tablaItem");
            $(this).find("tr").not(":first-child").find("td").addClass("tablaDato");
            $(this).find("tr").not(":first-child").find("td:first-child").addClass("tablaFirst");
            $(this).find("tr").not(":first-child").find("td:last-child").addClass("tablaLast");

            //Espacios vacios y
            $(this).find("tr").not(":first-child").find("td").each(function(){

                if($(this).html()==" ") { $(this).html("--").addClass("tablaVacio"); }
                else if($(this).is(":empty")) {  $(this).html("--").addClass("tablaVacio"); }



            });

            //Espacios por
            $(this).find("tr:first-child").find("td, th").each(function(){
            //$(this).find("td, th").each(function(){
                $(this).html($(this).html().replace(/ /g,' '));
            });

            $(this).find("tr:even").addClass("tablaPar");
            $(this).find("tr:odd").addClass("tablaImpar");


        });
        //Page Tabla

        //Page Tabla 2
        $(".JSpageTable").find("table").each(function() {

            $(this).addClass("pageTable");

            $(this).attr("width","0");
            $(this).attr("border","0");
            $(this).attr("cellpadding","0");
            $(this).attr("cellspacing","0");

            $(this).find("col, colgroup").remove();

            $(this).find("th, tr, td").removeAttr("width");
            $(this).find("th, tr, td").removeAttr("height");

            $(this).find("tr:first-child").addClass("tablaTitle");

            if ($(this).find("th").length > 0){
                $(this).find("tr").find("th").addClass("tablaDato");
                $(this).find("tr").find("th:first-child").addClass("tablaFirst");
                $(this).find("tr").find("th:last-child").addClass("tablaLast");
            }
            else{
                $(this).find("tr").find("td").addClass("tablaDato");
                $(this).find("tr").find("td:first-child").addClass("tablaFirst");
                $(this).find("tr").find("td:last-child").addClass("tablaLast");
            }

            $(this).find("tr").not(":first-child").addClass("tablaItem");
            $(this).find("tr").not(":first-child").find("td").addClass("tablaDato");
            $(this).find("tr").not(":first-child").find("td:first-child").addClass("tablaFirst");
            $(this).find("tr").not(":first-child").find("td:last-child").addClass("tablaLast");

            //Espacios vacios y
            $(this).find("tr").not(":first-child").find("td").each(function(){

                if($(this).html()==" ") { $(this).html("--").addClass("tablaVacio"); }
                else if($(this).is(":empty")) {  $(this).html("--").addClass("tablaVacio"); }



            });

            //Espacios por
            $(this).find("tr:first-child").find("td, th").each(function(){
            //$(this).find("td, th").each(function(){
                $(this).html($(this).html().replace(/ /g,' '));
            });

            $(this).find("tr:even").addClass("tablaPar");
            $(this).find("tr:odd").addClass("tablaImpar");


        });
        //Page Tabla 2
    }

    //Paginas Calendario ***


    //sugerencias click
    $(".pageCont > .container > .left > .sugerenciasHome form .block .leftSide .iconos .miniBoton").click(function(){
        var target = $(this).attr("id");

        $(this).parent().find("input[type='radio']").removeAttr("checked");
        $(this).parent().find(".miniBoton").removeClass("isChecked");
        $(this).parent().find("."+target+"input").attr('checked', 'checked');
        $(this).addClass("isChecked");

        $(".pageCont > .container > .left > .sugerenciasHome form .block .leftSide .iconos .miniBoton .foto img").each(function(){

            $(this).attr("src",$(this).attr("src").replace("_b.png", "_a.png"));

        });


        $(this).parent().find("#"+target+" .foto img").attr("src",$(this).parent().find("#"+target+" .foto img").attr("src").replace("_a.png", "_b.png"));

    });

    //Encuestas click 1
    $(".pageCont > .container > .left > .encuestasHome form .block .leftSide .iconos.numero1 .miniBoton").click(function(){
        var target = $(this).attr("id");

        $(this).parent().find("input[type='radio']").removeAttr("checked");
        $(this).parent().find(".miniBoton").removeClass("isChecked");
        $(this).parent().find("."+target+"input").attr('checked', 'checked');
        $(this).addClass("isChecked");

        $(".pageCont > .container > .left > .encuestasHome form .block .leftSide .iconos.numero1 .miniBoton .foto img").each(function(){

            $(this).attr("src",$(this).attr("src").replace("_b.png", "_a.png"));

        });


        $(this).parent().find("#"+target+" .foto img").attr("src",$(this).parent().find("#"+target+" .foto img").attr("src").replace("_a.png", "_b.png"));

    });

    //Encuestas click 2
    $(".pageCont > .container > .left > .encuestasHome form .block .leftSide .iconos.numero2 .miniBoton").click(function(){
        var target = $(this).attr("id");

        $(this).parent().find("input[type='radio']").removeAttr("checked");
        $(this).parent().find(".miniBoton").removeClass("isChecked");
        $(this).parent().find("."+target+"input").attr('checked', 'checked');
        $(this).addClass("isChecked");

        $(".pageCont > .container > .left > .encuestasHome form .block .leftSide .iconos.numero2 .miniBoton .foto img").each(function(){

            $(this).attr("src",$(this).attr("src").replace("_b.png", "_a.png"));

        });


        $(this).parent().find("#"+target+" .foto img").attr("src",$(this).parent().find("#"+target+" .foto img").attr("src").replace("_a.png", "_b.png"));

    });




    //Formulario Contacto
    $(".pageCont > .container > .left > .contactoHome form").find("input[type='submit']").click(function(e){


        $(this).parent().each(function(){

            if( !( checkEmpty($("#nombre").val()) ) ||
                !( checkEmpty($("#email").val()) )
            ){
                showAlert("Formulario de contacto","Hubo un problema al enviar, por favor complete todos los campos");
                e.preventDefault();
            }
            else if( !(emailValido( $("#email").val() )) )
            {
                showAlert("Formulario de contacto","Hubo un problema al enviar, por favor ingrese un E-Mail válido");
                e.preventDefault();
            }
            else if( $.trim( $("#mensaje").val() ) == "" )
            {
                showAlert("Formulario de contacto","Hubo un problema al enviar, por favor complete todos los campos");
                e.preventDefault();
            }
            else
            {
                $(this).submit();
            }

        });


    });
    //Formulario Contacto

    //Formulario Sugerencias
    $(".pageCont > .container > .left > .sugerenciasHome form").find("input[type='submit']").click(function(e){


        $(this).parent().each(function(){


            if( !( checkEmpty($("#rut").val()) ) ||
                !( checkEmpty($("#nombre").val()) ) ||
                !( checkEmpty($("#email").val()) )
            ){
                showAlert("Formulario de sugerencias y reclamos","Hubo un problema al enviar, por favor complete todos los campos");
                e.preventDefault();
            }
            else if( !(emailValido( $("#email").val() )) )
            {
                showAlert("Formulario de sugerencias y reclamos","Hubo un problema al enviar, por favor ingrese un E-Mail válido");
                e.preventDefault();
            }
            else if( $.trim( $("#mensaje").val() ) == "" )
            {
                showAlert("Formulario de sugerencias y reclamos","Hubo un problema al enviar, por favor complete todos los campos");
                e.preventDefault();
            }
            else
            {
                $(this).submit();
            }

        });


    });
    //Formulario Sugerencias

    //Formulario Encuestas
    $(".pageCont > .container > .left > .encuestasHome form").find("input[type='submit']").click(function(e){


        $(this).parent().each(function(){


            if( !( checkEmpty($("#numero_boleta").val()) ) && !( checkEmpty($("#numero_comanda").val()) )
            ){
                showAlert("Formulario de encuesta","Hubo un problema al enviar, falta: <b>Numero Boleta</b> o <b>Numero Comanda</b>");
                e.preventDefault();
            }
            else if ( $("#punto_venta").find("option:selected").attr("value") == "seleccione" ){
                showAlert("Formulario de encuesta","Hubo un problema al enviar, falta: <b>Punto de Venta</b>");
                e.preventDefault();
            }
            else if( !( checkEmpty($("#fecha").val()) )
            ){
                showAlert("Formulario de encuesta","Hubo un problema al enviar, falta: <b>Fecha</b>");
                e.preventDefault();
            }
            else if( !( checkEmpty($("#nombre_garzon").val()) )
            ){
                showAlert("Formulario de encuesta","Hubo un problema al enviar, falta: <b>Nombre Garzón</b>");
                e.preventDefault();
            }
            else if( !( checkEmpty($("#cliente").val()) )
            ){
                showAlert("Formulario de encuesta","Hubo un problema al enviar, falta: <b>Cliente</b>");
                e.preventDefault();
            }
            else if( !( $("#calidad_atencion1").hasAttr("checked") || $("#calidad_atencion1").attr("checked")=="checked" || $("#calidad_atencion1").is(":checked") ) &&
                     !( $("#calidad_atencion2").hasAttr("checked") || $("#calidad_atencion2").attr("checked")=="checked" || $("#calidad_atencion2").is(":checked") ) &&
                     !( $("#calidad_atencion3").hasAttr("checked") || $("#calidad_atencion3").attr("checked")=="checked" || $("#calidad_atencion3").is(":checked") ) &&
                     !( $("#calidad_atencion4").hasAttr("checked") || $("#calidad_atencion4").attr("checked")=="checked" || $("#calidad_atencion4").is(":checked") ) &&
                     !( $("#calidad_atencion5").hasAttr("checked") || $("#calidad_atencion5").attr("checked")=="checked" || $("#calidad_atencion5").is(":checked") ) ){
                showAlert("Formulario de encuesta","Hubo un problema al enviar, falta seleccionar: <b>Calidad Atención</b>");
                e.preventDefault();
            }
            else if( !( $("#calidad_comida1").hasAttr("checked") || $("#calidad_comida1").attr("checked")=="checked" || $("#calidad_comida1").is(":checked") ) &&
                     !( $("#calidad_comida2").hasAttr("checked") || $("#calidad_comida2").attr("checked")=="checked" || $("#calidad_comida2").is(":checked") ) &&
                     !( $("#calidad_comida3").hasAttr("checked") || $("#calidad_comida3").attr("checked")=="checked" || $("#calidad_comida3").is(":checked") ) &&
                     !( $("#calidad_comida4").hasAttr("checked") || $("#calidad_comida4").attr("checked")=="checked" || $("#calidad_comida4").is(":checked") ) &&
                     !( $("#calidad_comida5").hasAttr("checked") || $("#calidad_comida5").attr("checked")=="checked" || $("#calidad_comida5").is(":checked") ) ){
                showAlert("Formulario de encuesta","Hubo un problema al enviar, falta seleccionar: <b>Calidad Comida</b>");
                e.preventDefault();
            }
            /*else if( $.trim( $("#mensaje").val() ) == "" )
            {
                showAlert("Formulario de encuesta","Hubo un problema al enviar, por favor complete todos los campos");
                e.preventDefault();
            }*/
            else
            {
                $(this).submit();
            }

        });


    });
    //Formulario Sugerencias

    //Formulario Invitados
    function miniCheckOpcional(ID1,ID2){

        if( checkEmpty(ID1.val()) || checkEmpty(ID2.val()) )
        {
            if( !( checkEmpty(ID1.val()) ) || !( checkEmpty(ID2.val()) ) ){
                showAlert("Formulario de visitas","Hubo un problema al enviar, por favor complete todos los campos del invitado");
                return true;
            }
            else if ( !(ValidaRutTxt(ID1)) ){
                showAlert("Formulario de visitas","Por favor ingrese un <b>Rut</b> válido del invitado (Sin Puntos, ej: 12345678-9)");
                return true;
            }
            else{
                return false;
            }
        }

    }

    $(".pageCont > .container > .left > .invitadosHome form").find("input[type='submit']").click(function(e){


        if($(this).hasClass("invitadosAranas"))
        {
            //console.log("aranas");

            $(this).parent().each(function(){

                if( !( checkEmpty($("#rut_socio").val()) ) ||
                    /*!( checkEmpty($("#rut1").val()) ) ||
                    !( checkEmpty($("#rut2").val()) ) ||
                    !( checkEmpty($("#rut3").val()) ) ||
                    !( checkEmpty($("#rut4").val()) ) ||
                    !( checkEmpty($("#rut5").val()) ) ||*/
                    !( checkEmpty($("#nombre_socio").val()) ) ||
                    /*!( checkEmpty($("#nombre1").val()) ) ||
                    !( checkEmpty($("#nombre2").val()) ) ||
                    !( checkEmpty($("#nombre3").val()) ) ||
                    !( checkEmpty($("#nombre4").val()) ) ||
                    !( checkEmpty($("#nombre5").val()) ) ||*/
                    !( checkEmpty($("#mail").val()) ) ||
                    !( checkEmpty($("#fecha").val()) )
                ){
                    showAlert("Formulario de visitas","Hubo un problema al enviar, por favor complete todos los campos<br><i>Nota: Sólo los campos de invitados son opcionales.</i>");
                    e.preventDefault();
                }
                else if( !(emailValido( $("#mail").val() )) )
                {
                    showAlert("Formulario de visitas","Hubo un problema al enviar, por favor ingrese un E-Mail válido");
                    e.preventDefault();
                }
                else if ( !(ValidaRutTxt($("#rut_socio"))) /*||
                          !(ValidaRutTxt($("#rut1"))) ||
                          !(ValidaRutTxt($("#rut2"))) ||
                          !(ValidaRutTxt($("#rut3"))) ||
                          !(ValidaRutTxt($("#rut4"))) ||
                          !(ValidaRutTxt($("#rut5")))*/

                ){
                    showAlert("Formulario de visitas","Por favor ingrese un <b>Rut</b> válido (Sin Puntos, ej: 12345678-9)<br><i>Nota: Sólo los campos de invitados son opcionales.</i>");
                    e.preventDefault();
                }
                /*
                else if( miniCheckOpcional($("#rut1"),$("#nombre1"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut2"),$("#nombre2"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut3"),$("#nombre3"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut4"),$("#nombre4"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut5"),$("#nombre5"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut6"),$("#nombre6"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut7"),$("#nombre7"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut8"),$("#nombre8"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut9"),$("#nombre9"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut10"),$("#nombre10"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut11"),$("#nombre11"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut12"),$("#nombre12"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut13"),$("#nombre13"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut14"),$("#nombre14"))  )
                {
                    e.preventDefault();
                }
                else if( miniCheckOpcional($("#rut15"),$("#nombre15"))  )
                {
                    e.preventDefault();
                }
                */
                else if( !($("#aceptaterminos").hasAttr("checked")) || $("#aceptaterminos").attr("checked")!="checked" || !($("#aceptaterminos").is(":checked")) ){
                    showAlert("Formulario de visitas","Hubo un problema al enviar, debe aceptar los terminos y condiciones");
                    e.preventDefault();
                }
                else
                {
                    $(this).submit();
                    //alert("aranas enviado");
                    //e.preventDefault();
                }
            });

        }
        else
        {
            //console.log("grange");

            $(this).parent().each(function(){

                if( !( checkEmpty($("#rut_socio").val()) ) ||
                    !( checkEmpty($("#nombre_socio").val()) ) ||
                    !( checkEmpty($("#nombre_socio_hijo").val()) ) ||
                    /*!( checkEmpty($("#nombre1").val()) ) ||
                    !( checkEmpty($("#nombre2").val()) ) ||
                    !( checkEmpty($("#nombre3").val()) ) ||*/
                    !( checkEmpty($("#mail").val()) ) ||
                    !( checkEmpty($("#fecha").val()) )
                ){
                    showAlert("Formulario de visitas","Hubo un problema al enviar, por favor complete todos los campos<br><i>Nota: Sólo los campos de invitados son opcionales.</i>");
                    e.preventDefault();
                }
                else if( !(emailValido( $("#mail").val() )) )
                {
                    showAlert("Formulario de visitas","Hubo un problema al enviar, por favor ingrese un E-Mail válido");
                    e.preventDefault();
                }
                else if ( !(ValidaRutTxt($("#rut_socio"))) )
                {
                    showAlert("Formulario de visitas","Por favor ingrese un <b>Rut</b> válido (Sin Puntos, ej: 12345678-9)");
                    e.preventDefault();
                }
                else if( !($("#aceptaterminos").hasAttr("checked")) || $("#aceptaterminos").attr("checked")!="checked" || !($("#aceptaterminos").is(":checked")) ){
                    showAlert("Formulario de visitas","Hubo un problema al enviar, debe aceptar los terminos y condiciones");
                    e.preventDefault();
                }
                else
                {
                    $(this).submit();
                    //alert("grange enviado");
                    //e.preventDefault();

                }

            });
        }


    });
    //Formulario invitados

    //Click select invitados
    $("#numero_invitados").change(function() {

        var valor = parseInt($(this).find("option:selected").attr("value"));

        $(".pageCont > .container > .left > .invitadosHome form .block  .camposGeneral .campo.muestra_invitados").removeAttr("style");
        //$(".pageCont > .container > .left > .invitadosHome form .block  .camposGeneral .campo.muestra_"+valor).css("display","block");
        for(var i = 1; i < valor; i++) {

            $(".pageCont > .container > .left > .invitadosHome form .block  .camposGeneral .campo.muestra_"+(i+1)).css("display","block");

        }

    });
    //Click select invitados




    //****** SUMMER 2017

    //Formulario Invitados
    function miniCheckOpcional2(ID1,ID2){

        if( checkEmpty(ID1.val()) || checkEmpty(ID2.val()) )
        {
            if( !( checkEmpty(ID1.val()) ) || !( checkEmpty(ID2.val()) ) ){
                showAlert("Formulario Summer","Hubo un problema al enviar, por favor complete todos los campos");
                return true;
            }
            else if ( !(ValidaRutTxt(ID1)) ){
                showAlert("Formulario Summer","Por favor ingrese un <b>Rut</b> válido (Sin Puntos, ej: 12345678-9)");
                return true;
            }
            else{
                return false;
            }
        }

    }



    $(".pageCont > .container > .left > .summer2017Home form").find("input[type='submit']").click(function(e){
        //checkEmptyGrupal(".summer2017Home .camposGeneral .campo input[type='text']");
        e.preventDefault();

        $(this).parent().each(function(){
        //*******************************************

        if( checkEmptyGrupal(".summer2017Home .camposGeneral .campo input[type='text']") == false ){
            showAlert("Formulario Summer","Hubo un problema al enviar, por favor complete todos los campos");
            e.preventDefault();
        }
        else if( checkEmptyGrupalRut(".summer2017Home .camposGeneral .campo input[name*='rut']") == false ){
            showAlert("Formulario Summer","Por favor ingrese un <b>Rut</b> válido (Sin Puntos, ej: 12345678-9)");
            e.preventDefault();
        }
        else if( !($("#aceptaterminos").hasAttr("checked")) || $("#aceptaterminos").attr("checked")!="checked" ){
            showAlert("Formulario Summer","Hubo un problema al enviar, debe aceptar los terminos y condiciones");
            e.preventDefault();
        }
        else
        {
            $(".pageCont > .container > .left > .summer2017Home form").submit();
            //alert("summer enviado"); e.preventDefault();

        }

        //*******************************************
        });

    });
    //Formulario invitados








    //Formularios Inscripcion Deportes
    $(".pageCont > .container > .left > .inscDeportesHome form").find("input[type='submit']").click(function(e){

        e.preventDefault();

        $(this).parent().each(function(){

            if(checkEmptyGrupal(".inscDeportesHome .camposGeneral .campo input[type='text']") == false ){
                showAlert("Formulario Inscripción Deportes","Hubo un problema al enviar, por favor complete todos los campos");
                e.preventDefault();
            }
            else if( checkEmptyGrupalRut(".inscDeportesHome .camposGeneral .campo input[name*='rut']") == false ){
                showAlert("Formulario Inscripción Deportes","Por favor ingrese un <b>Rut</b> válido (Sin Puntos, ej: 12345678-9)");
                e.preventDefault();
            }
            else if( checkEmptyGrupalCheckbox(".inscDeportesHome .camposGeneral .check_deportes") == false ){
                showAlert("Formulario Inscripción Deportes","Seleccione al menos un deporte");
                e.preventDefault();
            }
            else if($('#div-principal').find('.familia-activa').length > 0 && (checkEmptyParent('padre') == 'error' || checkEmptyParent('madre') == 'error')) {
                showAlert("Formulario Inscripción Deportes","Por favor ingrese un <b>Rut</b> válido (Sin Puntos, ej: 12345678-9)");
                e.preventDefault();
            }
            else if (setInputIfEmpty() == false) {
                showAlert("Formulario Inscripción Deportes", "Por favor corrija la información de los apoderados");
                e.preventDefault();
            }
            else if( !($("#aceptaterminos").hasAttr("checked")) || $("#aceptaterminos").attr("checked")!="checked" || !($("#aceptaterminos").is(":checked")) ){
                showAlert("Formulario Inscripción Deportes","Hubo un problema al enviar, debe aceptar los terminos y condiciones");
                e.preventDefault();
            }
            else
            {
                $(".pageCont > .container > .left > .inscDeportesHome form").submit();
            }
        });
    });


    //Click select invitados Drportes
    $("#numero_invitados3").change(function() {

        var valor = parseInt($(this).find("option:selected").attr("value"));

        $(".pageCont > .container > .left > .inscDeportesHome form .block  .camposGeneral .campo.muestra_invitados").removeAttr("style");

        for(var i = 1; i < valor; i++) {
            $(".pageCont > .container > .left > .inscDeportesHome form .block  .camposGeneral .campo.muestra_"+(i+1))
                .css("display","block");
        }

        $('#div-principal').find('.familia-activa').removeClass('familia-activa')

    });
    //Click select invitados Drportes


    //Formularios Inscripcion Deportes

    //Click titular
    var summerTitularCheckA = 0;
    var summerTitularCheckB = 0;

    function summerTitular(){

        if( summerTitularCheckA == 1 && summerTitularCheckB == 1){

            $(".summerTitular").css("display","inline-block");
            $(".summerTitular .campo").css("display","block");
        }
        else{
            $(".summerTitular").css("display","none");
            $(".summerTitular .campo").css("display","none");
        }
    }

    $(".pageCont > .container > .left > .summer2017Home form .socio_padre_Si").click(function(e){
        summerTitularCheckA = 0;
        summerTitular();
    });

    $(".pageCont > .container > .left > .summer2017Home form .socio_madre_Si").click(function(e){
        summerTitularCheckB = 0;
        summerTitular();
    });

    $(".pageCont > .container > .left > .summer2017Home form .socio_padre_No").click(function(e){
        summerTitularCheckA = 1;
        summerTitular();
    });

    $(".pageCont > .container > .left > .summer2017Home form .socio_madre_No").click(function(e){
        summerTitularCheckB = 1;
        summerTitular();
    });

    //Click select invitados
    $("#numero_invitados2").change(function() {

        var valor = parseInt($(this).find("option:selected").attr("value"));

        $(".pageCont > .container > .left > .summer2017Home form .block  .camposGeneral .campo.muestra_invitados").removeAttr("style");
        //$(".pageCont > .container > .left > .invitadosHome form .block  .camposGeneral .campo.muestra_"+valor).css("display","block");
        for(var i = 1; i < valor; i++) {

            $(".pageCont > .container > .left > .summer2017Home form .block  .camposGeneral .campo.muestra_"+(i+1)).css("display","block");

        }

    });
    //Click select invitados

    //****** SUMMER 2017




    //Formulario Eventos
    $(".pageCont > .container > .left > .eventosReservas form").find("input[type='submit']").click(function(e){


        $(this).parent().each(function(){


            if( !( checkEmpty($("#fecha").val()) ) ||
                !( checkEmpty($("#numeropersonas").val()) ) ||
                !( checkEmpty($("#rut").val()) ) ||
                !( checkEmpty($("#nombrecompleto").val()) ) ||
                !( checkEmpty($("#email").val()) )
            ){
                showAlert("Formulario de eventos","Hubo un problema al enviar, por favor complete todos los campos");
                e.preventDefault();
            }
            //else if( !( checkEmpty($("#SelectorTipoActividad").val()) )  )
            else if( $("#SelectorTipoActividad").find("option:selected").attr("value") == "" || $("#tiposervicio").find("option:selected").attr("value") == "")
            {
                showAlert("Formulario de eventos","No puede enviar la solicitud si no ha seleccionado un evento o actividad");
                e.preventDefault();
            }
            else if( !(emailValido( $("#email").val() )) )
            {
                showAlert("Formulario de eventos","Hubo un problema al enviar, por favor ingrese un E-Mail válido");
                e.preventDefault();
            }
            else if( !($("#aceptaterminos").hasAttr("checked")) || $("#aceptaterminos").attr("checked")!="checked" || !($("#aceptaterminos").is(":checked")) ){
                showAlert("Formulario de eventos","Hubo un problema al enviar, debe aceptar los terminos y condiciones");
                e.preventDefault();
            }
            else
            {
                $(this).submit();
            }

        });


    });
    //Formulario Eventos

    //Click todo el block de noticias
    $(document).on("click",".homeNoticias > .container > .items span .block",function() {


            var enlace = $(this).attr("enlace");
            var titulo = $(this).find(".titulo").html();

            ga('send', 'event', 'Noticia Home', 'Click', titulo); //Google Analytics

            //alert("titulo: "+titulo);
            window.location=enlace;
    });

    //Click todo el block de noticias (PRINCIPAL)
    $(document).on("click",".homeMainNoticia .container",function() {


            var enlace = $(this).attr("enlace");
            var titulo = $(this).find(".titulo").html();

            ga('send', 'event', 'Noticia Home', 'Click', titulo); //Google Analytics

            //alert("titulo: "+titulo);
            window.location=enlace;
    });

    //FIX parrafos margin
    /*$(".panel-body, .pageCont > .container > .left > .noticiasPage .texto").each(function(){
        $(this).find("p:last-child").css("margin-bottom","0px");
    });*/



    //Click Carta
    var cartaOpen=null;


    $(document).on("click", ".bannerPopupTexto.menuCarta h3", function() {

        if(this===cartaOpen) {//OFF
            //console.log("off");

            $(".bannerPopupTexto.menuCarta table").css("display", "none");
            $(".bannerPopupTexto.menuCarta h3").removeClass("open");

            cartaOpen=null;
        }
        else //ON
        {
            //console.log("on");

            $(".bannerPopupTexto.menuCarta table").css("display", "none");
            $(".bannerPopupTexto.menuCarta h3").removeClass("open");

            $(this).addClass("open");
            $(this).next("table").css("display", "table");

            cartaOpen=this;
        }

    });

    $(".homeBanner > .container .carousel.slide .item span div i .desc.special").each(function(e){


        if( $(this).find("a").attr("href").indexOf("https://pwcc.cl") == 0 ||
            $(this).find("a").attr("href").indexOf("https://www.pwcc.cl") == 0 ||
            $(this).find("a").attr("href").indexOf("https://pwcc.site") == 0 ||
            $(this).find("a").attr("href").indexOf("javascript") == 0)
        {
            //alert("es sitio interno");
        }
        else
        {
            //alert("es sitio EXterno");
            $(this).find("a").attr("target","_blank");
        }

        //e.preventDefault();

    });

    $(".pageBanner .container-fluid .carousel.slide .item div.boton a").each(function(e){


        if( $(this).attr("href").indexOf("https://pwcc.cl") == 0 ||
            $(this).attr("href").indexOf("https://www.pwcc.cl") == 0 ||
            $(this).attr("href").indexOf("https://pwcc.site") == 0 ||
            $(this).attr("href").indexOf("javascript") == 0)
        {
            //alert("es sitio interno");
        }
        else
        {
            //alert("es sitio EXterno");
            $(this).attr("target","_blank");
        }

        //e.preventDefault();

    });

/* ================================================= DOCUMENT READY ================================================= */

});



$(window).bind("load", function() {

/* ================================================= WINDOWS LOAD ================================================= */



/* ================================================= WINDOWS LOAD ================================================= */

});



$(document).on("responsiveCode", function(event, bodyWidth, bodyHeight, bodyOrientation, bodyScreen){

/* ================================================= RESPONSIVE CODE ================================================= */

    $(".breaking > .container > span").each(function(){
        var anchoTexto = $(this).width();

        $(this).find(".center").css("width", (anchoTexto - 175 - 175));

    });

    if(isHome){//****** IS HOME ******//

        $(".homeMainNoticia").each(function(){
            var altoTexto = $(this).height();

            $(this).find(".fondo1").css("height", altoTexto);
            $(this).find(".fondo2").css("height", altoTexto);

        });

        $(".homeVideo").each(function(){
            var altoTexto = $(this).height();

            $(this).find(".fondo1").css("height", altoTexto);
            $(this).find(".fondo2").css("height", altoTexto);

        });

        if (bodyWidth > bodyScreen.tablet)
        {
            $(".homeNoticias").each(function(){
                var anchoTexto = $(this).find(".items").width();

                $(this).find(".block").css("width", (anchoTexto / 3));

            });

            $( ".homeNoticias > .container > .items span").removeAttr("style");
        }
        else{
            $(".homeNoticias").each(function(){
                var anchoTexto = $(this).find(".items").width();
                var altoTexto = $(this).find(".items").height();

                var anchoItems = $(this).find(".block").width();
                var altoItems = $(this).find(".block").height();

                $(this).find(".block").removeAttr("style");

                $(this).find(".block").css("width", anchoTexto);
                $(this).find("span").css("width", (anchoItems * 4));
                $(this).find("span").css("height", altoItems);
                $(this).find(".buttonLeft").css("height", altoItems);
                $(this).find(".buttonRight").css("height", altoItems);

            });

        }


        $(".homeGalerias").each(function(){
            var anchoFoto = $(this).find(".left").width();
            var anchoTexto = $(this).find(".right").width();
            var cantidadItems = 6;

            if (bodyWidth > bodyScreen.tablet)
            {
                $(this).find(".icon").removeAttr("style");
            }
            else
            {

                if ($(this).find(".icon").eq(5).css("display")=="none")
                {
                    if ($(this).find(".icon").eq(4).css("display")=="none")
                    {
                        if ($(this).find(".icon").eq(3).css("display")=="none")
                        {
                            cantidadItems = 3;
                        }
                        else
                        {
                            cantidadItems = 4;
                        }
                    }
                    else
                    {
                        cantidadItems = 5;
                    }
                }
                else
                {
                    cantidadItems = 6;
                }

                $(this).find(".icon").css("width",(anchoTexto / cantidadItems));

            }

        });

    }//****** IS HOME ******//

    if(!(isHome)){//****** NOT HOME ******//

        //Ancho titulo pagina
        $(".pageTitle > .container").each(function(){
            var anchoTexto = $(this).width();
            var altoTexto = $(this).find(".left").height();
            var anchoVolver = $(this).find(".right").width();

            if (bodyWidth > bodyScreen.phone)
            {
                $(this).find(".left").css("width", (anchoTexto - 15 - anchoVolver));
                $(this).find(".right").css("height", altoTexto);
            }
            else{
                $(this).find(".left").removeAttr("style");
                $(this).find(".right").removeAttr("style");
            }
        });

        //Altura contenedor
        if($(".pageCont > .container > .left.withSidebar").length > 0)
        {
            $(".pageCont > .container").each(function(){

                if (bodyWidth > bodyScreen.desktop)
                {
                    $(this).find(".left").addClass("isDesktop");
                    pageContResize();
                }
                else{
                    $(this).find(".left").removeClass("isDesktop");
                    $(this).find(".right").removeAttr("style");
                }
            });
        }

        //Ancho Servicios Eventos
        $(".pageCont > .container > .left > .eventosPage .servicios").each(function(){
            var anchoTexto = $(this).find(".items").width();
            var anchoTextoIcon = $(this).find(".items img").width();
            var anchoTextoDesc = $(this).find(".items b").width();

            if (bodyWidth > bodyScreen.tablet)
            {
                $(this).find(".items a").css("width", (anchoTexto / 3));
                $(this).find(".items b").css("width", ((anchoTexto / 3) - anchoTextoIcon));
            }
            else{
                $(this).find(".items a").removeAttr("style");
                $(this).find(".items b").removeAttr("style");
            }

        });

        //Altura contenedor
        if(!($(".pageCont > .container > .left > .deportesPage .infoContactoDep .staff .item.last").length > 0))
        {
            $(".pageCont > .container > .left > .deportesPage .infoContactoDep .staff").each(function(){

                $(this).find(".item.first").css("background","none");
                $(this).find(".item.first").css("padding-bottom","0px");

            });
        }

        //Altura contenedor 2
        if(!($(".pageCont > .container > .left > .actividadesPage .infoContactoDep .staff .item.last").length > 0))
        {
            $(".pageCont > .container > .left > .actividadesPage .infoContactoDep .staff").each(function(){

                $(this).find(".item.first").css("background","none");
                $(this).find(".item.first").css("padding-bottom","0px");

            });
        }

        //Ancho Horarios Restaurants
        $(".pageCont > .container > .left > .restaurantsPage .contenidos").each(function(){
            var anchoTexto = $(this).find(".item").width();
            var anchoTextoIcon = $(this).find(".item img").width();
            var anchoTextoDesc = $(this).find(".item b").width();


            $(this).find(".item span").css("width", (anchoTexto - anchoTextoIcon - 10));


        });


        //Letras buscador calendario
        $(".pageCont > .container > .left > .calendarioHome .campos").each(function(){

            if (bodyWidth < bodyScreen.phone)
            {
                $(this).find(".campo input[type='submit']").attr("value","");
                $(this).find(".campo input[type='submit']").addClass("isBackground");
            }
            else
            {
                $(this).find(".campo input[type='submit']").attr("value","Buscar");
                $(this).find(".campo input[type='submit']").removeClass("isBackground");
            }

        });




        $(".pageCont > .container > .left > .quienes90anios > .texto").each(function(){
            var anchoFoto = $(this).find(".left").width();
            var anchoTexto = $(this).find(".right").width();
            var cantidadItems = 6;

            if (bodyWidth > bodyScreen.tablet)
            {
                $(this).find(".icon").removeAttr("style");
            }
            else
            {

                if ($(this).find(".icon").eq(5).css("display")=="none")
                {
                    if ($(this).find(".icon").eq(4).css("display")=="none")
                    {
                        if ($(this).find(".icon").eq(3).css("display")=="none")
                        {
                            cantidadItems = 3;
                        }
                        else
                        {
                            cantidadItems = 4;
                        }
                    }
                    else
                    {
                        cantidadItems = 5;
                    }
                }
                else
                {
                    cantidadItems = 6;
                }

                $(this).find(".icon").css("width",(anchoTexto / cantidadItems));
                $(this).find(".icon:first").css("padding-left","0px");
                $(this).find(".icon:last").css("padding-right","0px");


            }

        });
    }

/* ================================================= RESPONSIVE CODE ================================================= */

});